import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OrderTimer from "./orderTimer";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import AddItem from "../SearchBox";
import useDashboard from "../../pages/Dashboard/dashboard-hook";
import { useEffect } from "react";
import { Cart, Selection, Status } from "../../generated/graphql";
import { cloneDeep } from "lodash";
interface orderProps {
  id: string;
  orderColor: string;
  orderRow: any;
  changeOrderStatus: (id: string, order_id: string, room_no: number) => void;
  CanselOrder: (id: string, order_id: string, room_no: number) => void;
  dineInItemsById: any;
}
type ExtraOptions = {
  name: string;
  price: number;
};
const OrderCard = ({
  orderRow,
  id,
  orderColor,
  changeOrderStatus,
  CanselOrder,
  dineInItemsById,
}: orderProps) => {
  const { handleEditItems, open, setOpen, details } = useDashboard();

  const [newOrder, setNewOrder] = React.useState<any>(orderRow);

  const [orderTotal, setOrderTotal] = React.useState<number>(0);

  function calculateTotalPrice(carts: Cart[]) {
    let totalPrice = 0;

    // Iterate over each selection in the cart
    for (const cart of carts) {
      if (!cart.selections) {
        continue;
      }
      for (const selection of cart.selections) {
        if (!selection) {
          continue;
        }
        // Iterate over each item in the selection
        for (const item of selection.selection_items) {
          // Add the price of the item to the total
          totalPrice += item?.price || 0;
        }
      }
    }

    return totalPrice;
  }

  useEffect(() => {
    let extraTotal = 0;
    let selectionTotal = 0;
    if (newOrder?.cart_items?.length) {
      const total = newOrder?.cart_items?.reduce(
        (accumulator: number, currentValue: any) => {
          if (currentValue?.extras) {
            const extra = currentValue?.extras;

            extraTotal = extra?.reduce(
              (acc: number, curr: ExtraOptions) => acc + curr?.price,
              0
            );
          }

          let price =
            currentValue?.price ||
            dineInItemsById?.[currentValue.dineInItem_id]?.price ||
            dineInItemsById?.[currentValue.id]?.price ||
            0;
          return extraTotal + accumulator + currentValue.quantity * price;
        },
        0
      );
      if (newOrder?.cart_items?.length) {
        selectionTotal = calculateTotalPrice(newOrder?.cart_items);
      }

      setOrderTotal(total + selectionTotal);
    }
  }, [newOrder, dineInItemsById]);

  const handleClickOpen = () => {
    setOpen(true);
    setNewOrder(orderRow);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleIncrementQuantity = (id: string) => {
    const IncrimentQuat = { ...newOrder };
    const incOrder = IncrimentQuat.cart_items.map((Order: any) =>
      Order.dineInItem_id === id
        ? { ...Order, quantity: Order.quantity + 1 }
        : Order
    );

    setNewOrder({ ...IncrimentQuat, cart_items: incOrder });
  };

  const handleDecrementQuantity = (id: string) => {
    const DecrimentQuat = { ...newOrder };
    const decOrder = DecrimentQuat.cart_items
      .filter((newOrder: any) =>
        newOrder.dineInItem_id === id && newOrder.quantity <= 1 ? false : true
      )
      .map((newOrder: any) =>
        newOrder.dineInItem_id === id
          ? {
              ...newOrder,
              quantity: newOrder.quantity > 0 ? newOrder.quantity - 1 : 0,
            }
          : newOrder
      );

    setNewOrder({ ...DecrimentQuat, cart_items: decOrder });
  };

  const cancelExtras = (extraName: string, id: string) => {
    let extraOptions = { ...newOrder };
    const index = extraOptions?.cart_items.findIndex(
      (order: any) => order.dineInItem_id === id
    );
    const extraItemList = extraOptions?.cart_items[index].extras;

    const extraIndex = extraItemList.indexOf(extraName);
    const filteredExtra = extraItemList.splice(extraIndex, 1);
    setNewOrder({ ...extraOptions, extras: filteredExtra });
  };
  const cancelSelections = (selectionIndex: number, id: string) => {
    const orderCopy = cloneDeep(newOrder);

    const cartIndex = orderCopy?.cart_items.findIndex(
      (order: any) => order.dineInItem_id === id
    );
    const selectionCopy: Selection[] =
      orderCopy?.cart_items[cartIndex]?.selections;

    const index = (selectionCopy || []).findIndex(
      (item) => item.id === (selectionCopy || [])[selectionIndex]?.id
    );

    if (selectionCopy[index].selection_items?.length <= 1) {
      selectionCopy.splice(index, 1);
    } else {
      const siIndex = selectionCopy[index].selection_items.findIndex(
        (item) =>
          item?.id ===
          (newOrder?.selections || [])[selectionIndex]?.selection_items[
            selectionIndex
          ]
      );
      selectionCopy[index].selection_items.splice(siIndex, 1);
    }
    orderCopy.cart_items[cartIndex].selections = selectionCopy;
    setNewOrder(orderCopy);
  };
  return (
    <>
      <Card sx={{ margin: 1 }}>
        <CardHeader
          style={{ backgroundColor: orderColor }}
          title={
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={1} mb={1}>
                <AccessTimeFilledIcon style={{ color: "#fff" }} />
                <OrderTimer created_at={newOrder.created_at} />
              </Stack>
              <Typography align="right" color="#fff">
                Room {newOrder.room_id}
              </Typography>
            </Stack>
          }
          subheader={
            <Stack direction="row" justifyContent="space-between">
              <Typography align="left" color="#fff">
                OrderID: {newOrder.order_id}
              </Typography>
              <Typography align="right" color="#fff">
                {newOrder.guest_name}
              </Typography>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          <Typography sx={{ fontSize: "17px", fontWeight: 600 }}>
            Order({newOrder.cart_items.length}Items)
          </Typography>
          <Divider />
          <Box mt={1} mb={2}>
            {newOrder?.cart_items?.map((cart: any,index:number) => {
              return (
                <>
                  <Stack
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                    spacing={0.5}
                  >
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="body2"
                        color="black"
                        fontWeight={600}
                      >
                        {cart.quantity}X
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize="15px"
                        fontWeight={600}
                      >
                        {cart.dineInItem_name}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body2"
                      fontSize="15px"
                      fontWeight={600}
                    >
                      {details?.currency}{" "}
                      {(cart.dineInItem_price * cart.quantity).toFixed(2)}
                    </Typography>
                  </Stack>

                  {cart?.extras?.map((extra: ExtraOptions,extraIndex: number) => {
                    return (
                      <Stack
                      key={`${cart.dineInItem_id}_extra_${extraIndex}`}
                        direction="row"
                        justifyContent="start"
                        marginLeft="35px"
                      >
                        <Typography variant="body2" fontSize="12px">
                          {extra.name}
                        </Typography>
                      </Stack>
                    );
                  })}
                  {cart?.selections?.map((select: any,selectionIndex:number) => {
                    return (
                      <div key={`${cart.dineInItem_id}_select_${selectionIndex}`}>
                        <Typography
                          variant="body2"
                          fontSize="15px"
                          sx={{ color: "#3E454B", marginLeft: "31px" }}
                        >
                          {select.name}
                        </Typography>
                        {select?.selection_items.map((items: any,itemIndex:number) => {
                          return (
                            <Stack
                              key={`${cart.dineInItem_id}_selectOItem_${itemIndex}`}
                              direction="row"
                              justifyContent="space-between"
                              spacing={0.5}
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                sx={{ color: "#3E454B", marginLeft: "32px" }}
                              >
                                <Typography
                                  variant="body2"
                                  fontSize="12px"
                                  fontWeight="200px"
                                >
                                  {items.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              );
            })}
          </Box>
          <Divider />
          <Stack direction="row" justifyContent="space-between" gap={1} mb={1}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Total Amount
            </Typography>
            <Typography
              variant="body2"
              align="right"
              color="black"
              style={{ fontWeight: 600 }}
            >
              {details?.currency} {orderTotal.toFixed(2)}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            align="center"
            color="black"
            style={{ fontWeight: 600 }}
          >
            {newOrder?.comment}
          </Typography>
        </CardContent>
        {newOrder?.status === Status.Pending ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
            sx={{ p: 1, width: "100%" }}
          >
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Button
                color="success"
                variant="contained"
                startIcon={
                  <FileDownloadDoneIcon sx={{ widht: 20, height: 20 }} />
                }
                onClick={() =>
                  changeOrderStatus(id, newOrder.order_id, newOrder.room_id)
                }
                sx={{ width: "100%" }}
              >
                Accept
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Button
                color="error"
                variant="contained"
                startIcon={<CloseOutlinedIcon sx={{ widht: 20, height: 20 }} />}
                onClick={() =>
                  CanselOrder(id, newOrder.order_id, newOrder.room_id)
                }
                sx={{ width: "100%" }}
              >
                cancel
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Button
                color="warning"
                variant="outlined"
                startIcon={<BorderColorIcon sx={{ widht: 15, height: 15 }} />}
                onClick={handleClickOpen}
                sx={{ width: "100%" }}
              >
                Modify
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            sx={{ p: 1 }}
          >
            <Button
              color="success"
              variant="outlined"
              startIcon={
                <FileDownloadDoneIcon sx={{ widht: 20, height: 20 }} />
              }
              fullWidth
              onClick={() =>
                changeOrderStatus(id, newOrder.order_id, newOrder.room_id)
              }
            >
              Complete
            </Button>
          </Stack>
        )}
      </Card>
      <Dialog onClose={handleClose} open={open}>
        <Card sx={{ margin: 1 }}>
          <CardHeader
            style={{ backgroundColor: orderColor }}
            title={
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1} mb={1}>
                  <AccessTimeFilledIcon style={{ color: "#fff" }} />
                  <Typography color="#fff" style={{ fontWeight: 600 }}>
                    {new Date(newOrder?.created_at).getMinutes() +
                      ":" +
                      new Date(newOrder?.created_at).getSeconds()}
                  </Typography>
                </Stack>
                <Typography align="right" color="#fff">
                  Room {newOrder.room_id}
                </Typography>
              </Stack>
            }
            subheader={
              <Stack direction="row" justifyContent="space-between">
                <Typography align="left" color="#fff">
                  OrderID: {newOrder.order_id}
                </Typography>
                <Typography align="right" color="#fff">
                  {newOrder.guest_name}
                </Typography>
              </Stack>
            }
          ></CardHeader>
          <CardContent>
            <Box sx={{height:300,overflow:"auto"}}>
              {newOrder?.cart_items.map((cart: any) => {
                return (
                  <>
                    <Stack
                      key={cart.dineInItem_id}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ pb: 1, pt: 1 }}
                    >
                      <Typography variant="body2" align="left">
                        {cart.dineInItem_name}
                      </Typography>
                      <Typography variant="body2" align="right">
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              handleDecrementQuantity(cart.dineInItem_id)
                            }
                          >
                            <RemoveCircleIcon color="error" fontSize="medium" />
                          </IconButton>
                          <Button
                            disabled
                            style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                          >
                            {cart.quantity}
                          </Button>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              handleIncrementQuantity(cart.dineInItem_id)
                            }
                          >
                            <AddCircleIcon color="success" fontSize="medium" />
                          </IconButton>
                        </ButtonGroup>
                      </Typography>
                    </Stack>
                    <Divider />
                    {cart?.extras?.map((ex: any) => {
                      return (
                        <>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ pb: 1, pt: 1 }}
                          >
                            <Typography
                              variant="h6"
                              align="left"
                              fontSize="12px"
                            >
                              {ex.name}
                            </Typography>
                            <Typography variant="body2" align="right">
                              <IconButton
                                sx={{ marginRight: "29px" }}
                                aria-label="delete"
                                onClick={() =>
                                  cancelExtras(ex.name, cart.dineInItem_id)
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Typography>
                          </Stack>
                          <Divider />
                        </>
                      );
                    })}
                    {cart?.selections?.map((select: any, index: number) => {
                      return (
                        <div key={index}>
                          <Typography variant="body2" fontSize="15px">
                            {select.name}
                          </Typography>
                          {select?.selection_items.map((items: any) => {
                            return (
                              <>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ pb: 1, pt: 1 }}
                                >
                                  <Typography
                                    variant="h6"
                                    align="left"
                                    fontSize="12px"
                                  >
                                    {items.name}
                                  </Typography>
                                  <Typography variant="body2" align="right">
                                    <IconButton
                                      sx={{ marginRight: "29px" }}
                                      aria-label="delete"
                                      onClick={() =>
                                        cancelSelections(
                                          index,
                                          cart.dineInItem_id
                                        )
                                      }
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </Typography>
                                </Stack>
                                <Divider />
                              </>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                );
              })}

            </Box>
              <AddItem newOrder={newOrder} setNewOrder={setNewOrder} />
          </CardContent>
          <CardActions disableSpacing>
            <Button
              fullWidth
              color="success"
              variant="contained"
              startIcon={<FileDownloadDoneIcon />}
              onClick={() =>
                handleEditItems({ ...newOrder, total: orderTotal })
              }
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default OrderCard;
